import {customElement, query} from "lit/decorators.js";
import {html, css, LitElement} from "lit";
import { IInstitutionData, apiSetInstitution } from "@digitale-lernwelten/ugm-client-lib";
import { getUser, ugmCheckStatus } from "../../user-group-management";
import { hideOverlay } from "../../overlay/overlay";

@customElement("license-modal")
export class LicensePage extends LitElement {
	static styles = css`
		*  {
			font-family: "Livvic", sans-serif;
		}

		.place-wrapper {
			display: flex;
			flex-flow: row nowrap;
			width: calc(50% + 3rem + 4px);
			margin-inline: auto;
			gap: 8px;
		}

		.place-wrapper > input {
			flex-grow: 1;
		}

		.form-container {
			padding-block: 3rem;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
		}

		.form-container > p {
			font-size: 2rem;
			width: calc(50% + 3rem + 4px);
			margin-inline: auto;
			margin-bottom: 8px;
		}

		input {
			font-family: "Livvic", sans-serif;
			font-size: 1.5rem;
			padding: 1rem 1.5rem;
			background-color: #FFFFFF;
			margin-top: 32px;
			width: 50%;
			margin-inline: auto;
			border: solid 2px #008BCF;
			border-radius: 2px;
			outline: none;
		}

		input[type=checkbox] {
			width: 1.2rem;
			height: 1.2rem;
			margin-right: 0.5rem;
		}

		.checkbox-wrapper {
			width: 50%;
			margin-inline: auto;
			text-align: center;
		}

		input[type="submit"] {
			font-family: "Livvic", sans-serif;
			font-size: 2rem;
			color: white;
			width: fit-content;
			margin-inline: auto;
			padding: 0.5rem 4rem;
			border: none;
			background-color: #008BCF;
			margin-top: 48px;
			cursor: pointer;
			border-radius: 2px;
			box-shadow: 0 3px 6px #00000029;
		}

		.center {
			display:block;
			margin:0 auto 1rem;
			text-align:center;
		}

		.right-align {
			display: flex;
			justify-content: space-between;
			margin-bottom: 24px;
		}

		a {
			text-decoration: none;
			color: #008BCF;
		}

		@media screen and (max-width: 500px) {
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1rem;
				width: 75%;
			}

			.payment-info {
				width: 75%;
			}
		}
	`;

	@query(".form-container")
		_formContainer: HTMLDivElement;

	@query('#invoice-summary')
		_invoiceSummary: HTMLDivElement;

	@query('#name-institution')
		_nameInstitution: HTMLInputElement;

	@query('#email')
		_email: HTMLInputElement;

	@query('#name-contact')
		_nameContact: HTMLInputElement;

	@query('#address')
		_address: HTMLInputElement;

	@query('#city')
		_city: HTMLInputElement;

	@query('#zip')
		_zip: HTMLInputElement;

	@query('#country')
		_country: HTMLInputElement;

	private userChanged: () => void;
	private _userChanged() {
		const user = getUser();
		if(user){
			this._email.value = user.email;
			if(user.institution && user.institution.email){
				this._address.value = user.institution.address;
				this._city.value = user.institution.city;
				this._country.value = user.institution.country;
				this._email.value = user.institution.email;
				this._nameContact.value = user.institution.nameContact;
				this._nameInstitution.value = user.institution.name;
				this._zip.value = user.institution.zip;
			}
		}
	}

	constructor(){
		super();
		this.userChanged = this._userChanged.bind(this);
	}

	connectedCallback(): void {
		super.connectedCallback();
		window.addEventListener("ugm-logged-in", this.userChanged);
	}

	disconnectedCallback(): void {
		super.disconnectedCallback();
		window.removeEventListener("ugm-logged-out", this.userChanged);
	}

	private _getFormFields():IInstitutionData {
		const name = this._nameInstitution.value;
		const nameContact = this._nameContact.value;
		const address = this._address.value;
		const email = this._email.value;
		const city = this._city.value;
		const zip = this._zip.value;
		const country = this._country.value;
		if(!name || !nameContact || !address || !city || !zip || !country){
			throw new Error("Invalid form fields");
		}
		return <IInstitutionData>{
			name,
			nameContact,
			address,
			city,
			zip,
			email,
			country,
			addInvoice: false
		};
	}

	private async _save(e: Event) {
		e.preventDefault();
		if(getUser()){
			await apiSetInstitution(this._getFormFields());
			await ugmCheckStatus();
			hideOverlay();
		}
	}

	render() {
		setTimeout(this.userChanged,0);
		return html`
			<form @submit="${this._save}">
				<div class="form-container">
					<p style="font-size: 2.4rem; text-align: center;">Rechnungsanschrift bearbeiten</p>
					<input id="name-institution" type="text" placeholder="Name der Institution" required/>
					<input id="name-contact" type="text" placeholder="Name des Ansprechpartners" required/>
					<input id="email" type="email" placeholder="E-Mail des Rechnungsempfängers" required/>
					<input id="address" type="text" placeholder="Straße, Hausnummer" required/>
					<div class="place-wrapper">
						<input id="city" type="text" placeholder="Ort" required/>
						<input id="zip" type="number" placeholder="Postleitzahl" minlength="5" maxlength="5" required/>
					</div>
					<input id="country" type="text" placeholder="Land" required/>

					<div class="checkbox-wrapper">
						<input type="submit" value="Speichern">
					</div>

					<p style="font-size: 1.4rem; padding-top: 2rem;">Falls Sie Ihren Account l&ouml;schen wollen, kontaktieren Sie bitte unser Support-Team unter <a href="mailto:info@dilewe.de">info@dilewe.de</a></p>
				</div>
			</form>
		`;
	}
}
