import {html, css, LitElement, unsafeCSS} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {apiGetSelf} from "@digitale-lernwelten/ugm-client-lib";

enum Mode {
	LOGIN,
	SIGN_UP
}

import loginHamburgBG from "../../img/login-hamburg-bg.jpg";

@customElement('hamburg-login-page')
export class HamburgLoginPage extends LitElement {
	static styles = css`
		section {
			display: flex;
			justify-content: center;
			flex-flow: column nowrap;
			gap: 1rem;
			min-height: 75dvh;

			background: linear-gradient(0deg, rgba(42, 176, 215, 0.83), rgba(42, 176, 215, 0.83)), url(${unsafeCSS(loginHamburgBG)});
			background-size: cover;
		}

		.tab-item {
			position: relative;
			font-family: "Livvic", sans-serif;
			font-weight: bolder;
			font-size: 34px;
			color: white;
		}

		.tab-item[data-selected="true"]::after {
			content: "";
			position: absolute;
			left: -7.5%;
			bottom: -10px;

			width: 115%;
			height: 4px;
			background-color: white;
		}

		.tab-bar {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			gap: 48px;
		}

		@media screen and (max-width: 500px) {
			.tab-item {
				font-size: 24px;
			}

			.tab-bar {
				width: 90%;
				margin-inline: auto;
			}

		}
	`;

	@property()
		mode = Mode.LOGIN;

	@property()
		userLoggedIn = false;

	async connectedCallback() {
		super.connectedCallback();

		try {
			await apiGetSelf();
			this.userLoggedIn = true;
		} catch (err) {
			this.userLoggedIn = false;
		}
	}

	render() {
		return html`
			<hamburg-login-header></hamburg-login-header>
			<section>
				${ this.userLoggedIn ? html`<hamburg-logout></hamburg-logout>` : html`
					<div class="tab-bar">
						<span @click=${() => {this.mode = Mode.SIGN_UP;}} data-selected=${this.mode === Mode.SIGN_UP} class="tab-item">Registrieren</span>
						<span @click=${() => {this.mode = Mode.LOGIN;}} data-selected=${this.mode === Mode.LOGIN} class="tab-item">Anmelden</span>
					</div>
					${ this.mode === Mode.LOGIN ? html`<hamburg-login></hamburg-login>` : html `<hamburg-register accountType="hamburg"></hamburg-register>` }
				`};

			</section>
		`;
	}
}
