import configuration from "../../../configuration";
import { showModal } from "../../modal/modal";
import { initGlossary } from "../../content_types/glossary/glossary";
import { getLastParentSection, getParam } from "../../_helper";
import { apiGetSubmissionAsStudent, apiReadSubmissionCorrection } from "@digitale-lernwelten/ugm-client-lib";
import { addOrUpdateSubmission, getCorrectedSubmission, getSubmission } from "../submissions/submissions";

import "./corrections.css";
import { isDevMode } from "../../dev-tools";

export interface ICorrection {
	exerciseId: string
	annotatedText: string; // override due to the fallback to the submitted text
	text: string;
	read?: boolean;
}

export interface ICorrectionEvent {
	exerciseId: string,
	type: "read",
}

export const triggerListeners = (detail:ICorrectionEvent) => {
	window.dispatchEvent(new CustomEvent("ugm-correction", {detail}));
};

export const flushCorrections = () => {
	document.querySelectorAll(`correction-marker`).forEach(ele => ele.remove());
};

export const removeCorrections = (exerciseId: string) => {
	document.querySelectorAll(`section[content-type="exercise"][content-type-id="${exerciseId}"] correction-marker`).forEach(ele => ele.remove());
};

const correctionHandlers:Map<string, (exerciseId:string, correction?:ICorrection) => void> = new Map();
const setCorrectionHandler = (exerciseId:string, cb:((exerciseId:string, correction?:ICorrection) => void)) => correctionHandlers.set(exerciseId, cb);
export const emitCorrectionChanged = (exerciseId:string, correction?:ICorrection) => {
	const cb = correctionHandlers.get(exerciseId);
	if(cb){ cb(exerciseId, correction); }
};

const showCorrection = (correction:ICorrection) => {
	const exerciseId = correction.exerciseId;
	const exerciseContentEle = document.querySelector<HTMLElement>(`[content-type-id="${exerciseId}"] exercise-content`);
	if(!exerciseContentEle){return;}
	const exerciseContent = exerciseContentEle.innerHTML;
	const {
		annotatedText: correctionText,
		text: generalComment
	} = correction;
	const correctionMarkup = `<correction-exercise-content>${exerciseContent}</correction-exercise-content><correction-text>${correctionText}</correction-text><correction-general-comment>${generalComment}</correction-general-comment>`;
	const modal = showModal(correctionMarkup);
	initGlossary(); //to deal with glossary inside the correction modal
	Array.from(modal.querySelectorAll("fancy-exercise-editor, .ck-editor, input, button")).forEach(ele => ele.remove());
	Array.from(modal.querySelectorAll("details")).forEach((details, i, array) => {
		details.addEventListener("toggle", e => {
			details.open && array.forEach(d => {
				d!==e.target && (d.open=false);
			});
		});
		details.querySelector("img")?.addEventListener("click", () => {
			details.open = false;
		});
	});
	const submission = getCorrectedSubmission(exerciseId);
	if(submission){
		apiReadSubmissionCorrection(submission.id);
		if(submission.correction){
			submission.correction.read = true;
		}
		addOrUpdateSubmission(submission);
	}
	triggerListeners({exerciseId, type:"read"});
};

const tabButtonCorrectionRefreshHandler = (e:Event) => {
	const curButton = e.target as HTMLElement | null;
	if(!curButton){
		return;
	}
	const tabIndex = curButton.getAttribute("tab-index");
	const tabBox = getLastParentSection(curButton);
	if (!tabBox) {
		console.error("Can't find tabBox");
		return;
	}
	const corrections = Array.from(tabBox.querySelectorAll<HTMLElement>(`correction-marker`));
	corrections.forEach(c => {
		const curIndex = c.getAttribute("tab-index");
		if(tabIndex === curIndex){
			c.classList.remove("hidden");
		}else{
			c.classList.add("hidden");
		}
	});
};

const insertTabIcons = (exercise:HTMLElement) => {
	const tabBox = getLastParentSection(exercise);
	if (!tabBox) { return;}
	const tabs = Array.from(tabBox.querySelectorAll("tab-box-content")) as HTMLElement [];
	tabs.forEach( (tab, i) => {
		const correctionMarker = tabBox.querySelector(`correction-marker[tab-index="${i}"`);
		tabBox.querySelectorAll(`tab-box-header[tab-index="${i}"]`).forEach(tabButton => {
			if (correctionMarker) {
				tabButton.classList.add("correction");
			} else {
				tabButton.classList.remove("correction");
			}
			tabButton.addEventListener("click", tabButtonCorrectionRefreshHandler);
		});
	});
};

const initExerciseCorrectionHandler = (exercise:HTMLElement) => {
	const exerciseId = exercise.getAttribute("content-type-id");
	const parent = getLastParentSection(exercise);
	if(!exerciseId || !parent){return;}
	setCorrectionHandler(exerciseId, (exerciseId:string, correction?: ICorrection) => {
		if(!correction){
			removeCorrections(exerciseId);
			return;
		}
		const ref = parent.querySelector<HTMLElement>("markers-left");
		if (!ref) {
			console.error("Can't find markers-left");
			return;
		}
		let correctionMarker = parent.querySelector<HTMLElement>("correction-marker");
		if (!correctionMarker) {
			correctionMarker = document.createElement("correction-marker");
			ref.append(correctionMarker);
		}
		const tab = exercise.parentElement?.getAttribute("tab-index");
		if (tab) {
			correctionMarker.setAttribute("tab-index", tab);
			if (tab === "0") {
				correctionMarker.classList.remove("hidden");
			} else {
				correctionMarker.classList.add("hidden");
			}
		}
		correctionMarker.onclick = e => {
			e.preventDefault();
			e.stopPropagation();
			showCorrection(correction);
		};
		insertTabIcons(exercise);
	});
};

const showInitialCorrection = async (subID:string) => {
	let sub = getSubmission(subID);
	if(!sub){
		sub = await apiGetSubmissionAsStudent(subID);
	}
	if(!sub || (sub.state !== 'corrected')){
		console.log(`Couldn't find corrected submission with ID: ${subID}`);
	}
	if(isDevMode()){
		console.log(sub);
	}
	if(sub.correction){
		const exercise = document.querySelector<HTMLElement>(`section[content-type-id="${sub.exerciseId}"]`);
		if(exercise){
			exercise.scrollIntoView();
		}
		showCorrection({
			exerciseId: sub.exerciseId,
			read: sub.correction.read,
			text: sub.correction.text,
			annotatedText: sub.correction.annotatedText || sub.text
		});
	}
};

if(configuration.ugmEndpoint){
	setTimeout(() => {
		document.querySelectorAll<HTMLElement>(`section[content-type="exercise"]`).forEach(initExerciseCorrectionHandler);
	}, 0);

	setTimeout(() => {
		const showCorrection = getParam("showCorrection");
		if(showCorrection){
			showInitialCorrection(showCorrection);
		}
	}, 0);
}
