import {customElement} from "lit/decorators.js";
import {css, html, LitElement, unsafeCSS} from "lit";

import loginHamburgBG from "../../img/login-hamburg-bg.jpg";

@customElement("netbook-shop-page")
export class NetbookShopPage extends LitElement {
	static styles = css`
		.background {
			background: linear-gradient(0deg, rgba(42, 176, 215, 0.83), rgba(42, 176, 215, 0.83)), url(${unsafeCSS(loginHamburgBG)});
			background-size: cover;
			padding-bottom: 4rem;
			margin-bottom: 4rem;
		}

		.text-wrap {
			margin-bottom: 3rem;
			position: relative;
			background: linear-gradient(180.5deg, #FFFFFFFF 90%, #FFFFFF00 calc(90% + 1px));

			font-family: "Livvic", sans-serif;
			font-size: 1.75rem;
			font-weight: lighter;
			line-height: 1.5em;
		}

		@media screen and (max-width: 700px) {
			.text-wrap {
				font-size: 1.5rem;
				margin-bottom: 2.5rem;
			}
		}

		@media screen and (max-width: 500px) {
			.text-wrap {
				font-size: 1.4rem;
				margin-bottom: 2rem;
			}
		}

		@media screen and (max-width: 400px) {
			.text-wrap {
				font-size: 1.3rem;
				margin-bottom: 1.5rem;
			}
		}

		.text  {
			width: 100%;
			max-width: var(--content-width);
			padding: 1rem var(--content-padding) 3rem;
			box-sizing: border-box;
			margin: 0 auto;
		}

		p {
			hyphens: none;
		}

		a.button {
			font-family: "Livvic", sans-serif;
			font-size: 1.75rem;
			color: #26c88b;
			width: fit-content;
			margin: 0 auto;
			padding: 0.5rem 4rem;
			border: none;
			background: #fff;
			border-radius: 2px;
			box-shadow: 0 3px 6px #00000029;
			display: block;
			cursor: pointer;
			text-decoration: none;
			white-space: normal;
			line-height: 1.4em;
			transition: background-color 200ms;
			width: 100%;
			max-width: fit-content;
		}

		.text .button {
			background: #2AB0D7;
			color: #fff;
			margin-bottom: 2rem;
		}

		@media screen and (max-width: 900px) {
			a.button {
				font-size: 1.5rem;
			}
		}

		@media screen and (max-width: 700px) {
			a.button {
				font-size: 1.35rem;
			}
		}

		@media screen and (max-width: 500px) {
			a.button {
				font-size: 1.2rem;
				padding: 0.5rem 2rem;
			}
		}
	`;
	render() {
		return html`
			<div class="background">
				<netbook-header headerType="netbook"></netbook-header>
				<div class="text-wrap">
					<div class="text">
						<p>Das netBook Deutsch für die 5. und 6. Jahrgangsstufe ist das erste adaptive, interaktive und multimediale Lehr- und Übungswerk für den Deutschunterricht. Es umfasst 60 methodisch-didaktisch strukturierte Unterrichtsmodule im Umfang von über 155 Unterrichtsstunden aus den Kompetenzbereichen Beschreiben, Erzählen, Sprechen und Zuhören, Lesen und Sprachbetrachtung. </p>
						<a class="button" href="/inhalt/netbook-deutsch-module">Direkt ins netBook</a>
					</div>
				</div>
				<netbook-shop-form></netbook-shop-form>
			</div>
			<br/><br/>
		`;
	}
}
