import { apiSendSupportMessage } from "@digitale-lernwelten/ugm-client-lib";
import { hideModal, showModal } from "../modal/modal";
import "./support.css";
import { hideOverlay } from "../overlay/overlay";

export const initSupportButton = (button: HTMLElement) => {
	button.addEventListener("click", () => {
		const html = `<div class="support-modal">
			<h3>Support</h3>
			<p>Mit diesem Formular können Sie unserem Support-Team direkt eine Nachricht senden.</p>
			<form>
				<label for="email">E-Mail</label>
				<input type="email" id="email" name="email" />
				<label for="name">Name</label>
				<input type="text" id="name" name="name" />
				<label for="message">Nachricht</label>
				<textarea id="message" name="message"></textarea>
				<button type="submit">Senden</button>
			</form>
		</div>
		`;

		const modal = showModal(html);
		modal.querySelector("form")?.addEventListener("submit", async (event) => {
			event.preventDefault();
			const email = modal.querySelector<HTMLInputElement>("#email")?.value || '';
			const name = modal.querySelector<HTMLInputElement>("#name")?.value || '';
			const message = modal.querySelector<HTMLTextAreaElement>("#message")?.value || '';
			await apiSendSupportMessage(email, name, message);
			hideModal();

			const successModal = showModal(`<div class="support-modal">
				<h3>Nachricht gesendet</h3>
				<p>Ihre Nachricht wurde erfolgreich an unser Support-Team gesendet.</p>
			</div>`);
		});
	});
};

export const initAllSupportButtons = () => {
	document.querySelectorAll("support-button").forEach(initSupportButton);
};
setTimeout(initAllSupportButtons, 0);
